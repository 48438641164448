<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex sm6>
        <div class='headline green--text text--darken-4 font-weight-medium'>
          Allergy Survey Sheet
        </div>
      </v-flex>
      <v-flex xs12 sm12 lg12>
        <v-layout row wrap>
          <div class='filterStyle'>
            <v-text-field label='Patient Id:' v-model='patientId'>
              <template slot='prepend'>
                <v-icon class='filterIcon grey--text text--darken-1'
                  >fa fa-filter</v-icon
                >
              </template>
            </v-text-field>
          </div>
          <v-flex>
            <v-btn :disabled='loading' @click='searchPatient'>
              <v-icon class='iconText'>fa fa-search</v-icon>
              Search
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs6 sm6 lg6 v-show='patient.Id'>
        <PatientCard :patient='patient'></PatientCard>
      </v-flex>
      <v-flex sm12>
        <AllergySurveyComp ref='allergyComp'></AllergySurveyComp>
      </v-flex>
      <v-flex sm12>
        <v-btn color='primary' @click='saveData' :disabled='loading'>
          <v-icon class='iconText'>fa fa-save</v-icon>
          Save
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import PatientCard from '../../components/patients/PatientCard';
import AllergySurveyComp from '../../components/forms/PatientHistory';
import WebH from '../../api/web';
export default {
  components: {
    PatientCard,
    AllergySurveyComp
  },
  data() {
    return {
      loading: false,
      patientId: '',
      patient: {}
    };
  },
  created() {
    this.patientId = this.$store.getters['SettingsStore/patientId'];
    this.searchPatient();
  },
  methods: {
    async searchPatient() {
      if (this.patientId === '') {
        return;
      }
      this.$store.commit('SettingsStore/setPatientId', this.patientId);
      this.loading = true;
      this.patient = {};
      let url = '/patients/search?&name=&pid=' + this.patientId + '&mobile=';
      const res = await WebH.Get(url, 'patients');
      if (res.Success) {
        if (res.Data.length > 0) {
          this.patient = res.Data[0];
          //await this.getAllergySurvey();
        } else {
          await this.$notifyDlg.show('Invalid patient id', 'Error', 'error');
        }
      }
      this.loading = false;
    },

    async saveData() {
      if (
        this.patientId === '' ||
        this.patient === null ||
        this.patient.Id === null
      ) {
        await this.$notifyDlg.show('Invalid patient id', 'Error', 'error');
        return;
      }
      const vModel = {
        Treatments: this.$refs.allergyComp.getTreatments(),
        Family: this.$refs.allergyComp.getFamily(),
        Habits: this.$refs.allergyComp.getHabits(),
        Others: this.$refs.allergyComp.getOthers(),
        Children: this.$refs.allergyComp.getChildren(),
        PatientId: this.patient.PatientId,
        Mobile: this.patient.Mobile
      };

      await this.api_createAllergyForm();

      this.submit(vModel);
    },
    async api_createAllergyForm() {
      this.loading = true;
      const res = await WebH.Get1(
        '/PatientHistories/' + this.patient.Id + '/create',
        ''
      );
      this.loading = false;
      if (res.Success) {
        return true;
        // this.$notify({
        //   type: 'info',
        //   text: 'Successfully create a allergy form for the patient'
        // });
      } else {
        //await this.$notifyDlg.show(res.Data, 'Error', 'error');
        return false;
      }
    },
    async submit(data) {
      const loader = this.$loading.show();
      const res = await WebH.Post('/PatientHistories', data);
      loader.hide();
      if (res.Success) {
        await this.$notifyDlg.show(
          'Answers submitted. Thank you',
          'Info',
          'info'
        );
        this.$store.commit('FormsStore/reset');
        this.$refs.allergyComp.clear();
      } else {
        await this.$notifyDlg.show(res.Data, 'Error', 'error');
      }
    }
  }
};
</script>

<style scoped></style>
